import React from 'react';

const Menu = () => (
	<svg
		width="37px"
		height="26px"
		viewBox="0 0 37 26"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<g
			id="Tablet-&amp;-mobile"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="square">
			<g id="Home---Tablet" transform="translate(-696.000000, -37.000000)" stroke="#FFFFFF" strokeWidth="2">
				<g id="32px_menu-3" transform="translate(697.000000, 38.000000)">
					<line x1="35" y1="11.5" x2="0" y2="11.5" id="Path"></line>
					<line x1="35" y1="0.5" x2="0" y2="0.5" id="Path"></line>
					<line x1="35" y1="23.5" x2="17" y2="23.5" id="Path"></line>
				</g>
			</g>
		</g>
	</svg>
);

export default Menu;
