import { makeStyles } from '@material-ui/core';
import React from 'react';
import Colors from '../styles/colors';
import content from '../content/footer.json';
import Fjalla from '../fonts/Fjalla_One/fjalla';
import Dimensions, { defaultWidth } from '../styles/dimensions';
import FiraSans from '../fonts/Fira_Sans/firaSans';

const Footer = () => {
	const classes = useClasses();

	return (
		<footer className={classes.footer}>
			<div className={classes.container}>
				<span className={classes.copyright}>{content.copyright}</span>
				<nav className={classes.navigation}>
					{content.links.map((item, key) => (
						<React.Fragment key={key}>
							{key !== 0 && <span className={`${classes.middot} middot`}>&middot;</span>}
							<a href={item.src} target="_blank" className={classes.link}>
								<span className={classes.linkSpan}>{item.text}</span>
							</a>
						</React.Fragment>
					))}
				</nav>
			</div>
		</footer>
	);
};

export default Footer;

const useClasses = makeStyles({
	footer: {
		height: '5.25em',
		backgroundColor: Colors.pageBackgroundBlack,
		position: 'relative',
		'@media (max-width: 599px)': {
			height: 'auto',
			paddingTop: '2.25em',
			paddingBottom: '2.25em',
		},
	},
	container: {
		maxWidth: defaultWidth,
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		'@media (min-width: 600px)': {
			position: 'relative',
			top: '2.625em',
			transform: 'translateY(-50%)',
		},
		'@media (max-width: 599px)': {
			justifyContent: 'initial',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	navigation: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'@media (max-width: 599px)': {
			order: 1,
			width: '100%',
			justifyContent: 'initial',
			flexWrap: 'wrap',
		},
	},
	link: {
		margin: '0 1em',
		'@media (max-width: 599px)': {
			display: 'block',
			flexGrow: 0,
			maxWidth: 'calc(50% - 2em)',
			flexBasis: 'calc(50% - 2em)',
			pointerEvents: 'none',
			marginTop: '0.5em',
			marginBottom: '0.5em',
		},
	},
	linkSpan: {
		fontFamily: Fjalla.regular,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
		color: Colors.white,
		'@media (max-width: 599px)': {
			pointerEvents: 'auto',
		},
	},
	copyright: {
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
		color: Colors.whiteHalfOpacity,
		'@media (max-width: 1231px)': {
			paddingLeft: '1em',
		},
		'@media (max-width: 599px)': {
			order: 2,
			marginTop: '2.5em',
		},
	},
	middot: {
		fontFamily: Fjalla.regular,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
		color: Colors.white,
		fontWeight: 'bolder',
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
});
