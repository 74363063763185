/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import Header from './header';
import './layout.css';
import '../fonts/Fira_Sans/firaSans.css';
import '../fonts/Fjalla_One/fjalla.css';
import { makeStyles } from '@material-ui/core';
import Footer from './footer';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Dimensions from '../styles/dimensions';
import Colors from '../styles/colors';
import { Link } from 'gatsby';
import CookieModal from './cookieModal';
import Cookies from 'js-cookie';
import { useLocation } from '@reach/router';

const Layout = ({ children }) => {
	const [areCookiesAccepted, setAreCookiesAccepted] = React.useState(true);

	const cookieModalRef = React.useRef(null);

	const classes = useClasses();

	const location = useLocation();

	React.useEffect(() => {
		setTimeout(() => {
			setAreCookiesAccepted(Cookies.get('gatsby-gdpr-google-analytics') === 'true' ? true : false);
			cookieModalRef.current?.openDialog();
		}, 1000);
	}, []);

	const handleLinkClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (typeof window !== 'undefined') {
			const container = document.getElementById('___gatsby');

			// @ts-ignore
			if (event.target.nodeName === 'A' && window.location.pathname === event.target.getAttribute('href')) {
				container.scroll({ top: 0, behavior: 'smooth' });
			}
			// @ts-ignore
			else if (
				event.target.nodeName !== 'A' &&
				window.location.pathname === event.target.parentNode.getAttribute('href')
			) {
				container.scroll({ top: 0, behavior: 'smooth' });
			} else {
				container.scroll({ top: 0, behavior: 'auto' });
			}
		}
	}, []);

	return (
		<>
			<Header />
			<div>
				<main className={classes.content}>
					{children}
					{location.pathname === '/karriere' ? null : (
						<Link to="/angebot" className={classes.offerLink} onClick={handleLinkClick}>
							<div className={classes.offerContainer}>
								<span>Angebot sichern</span>
							</div>
						</Link>
					)}
				</main>
				<Footer />
				<CookieModal
					ref={cookieModalRef}
					areCookiesAccepted={areCookiesAccepted}
					setAreCookiesAccepted={setAreCookiesAccepted}
				/>
			</div>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;

const useClasses = makeStyles({
	content: {
		// paddingTop: '6.25em',
	},
	offerLink: {
		'@media (min-width: 600px)': {
			display: 'none',
			position: 'fixed',
			right: '1.75em',
			bottom: '0.75em',
		},
		'@media (max-width: 599px)': {
			position: 'fixed',
			right: '1em',
			bottom: '0.75em',
			// top: 'calc(100vh - 2em)',
			// bottom: 'calc(-100vh + 3.125em)',
			// transform: 'translateY(-100%)',
		},
	},
	offerContainer: {
		display: 'inline-flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.headerButtonBlack,
		backgroundColor: Colors.limeGreen,
		borderRadius: '4px',
		padding: '0.625em 1em',
		marginLeft: '2.5em',
		'@media (max-width: 599px)': {
			marginLeft: 0,
			boxShadow: '0 12px 32px -8px rgba(26,26,26,0.25)',
		},
	},
});
